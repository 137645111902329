import React from 'react';
import {Box, Button, Typography, Divider, TextField, Checkbox, FormControlLabel} from '@gosolo/components';
import {Google, Microsoft} from '@mui/icons-material';
import AuthLayout from '../entrypoints/AuthLayout';

interface Props {
    login_label: string;
    csrf_token: string;
    omniauthProviders: Array<{ name: string; path: string }>;
}

const Login: React.FC<Props> = (props) => {
    const {csrf_token, omniauthProviders} = props;

    const getProviderIcon = (providerName: string) => {
        switch (providerName) {
            case 'google_oauth2':
                return <Google/>;
            case 'microsoft_oauth2':
                return <Microsoft/>;
            default:
                return null;
        }
    };

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>, path: string) => {
        e.preventDefault();
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = path;
        const csrfInput = document.createElement('input');
        csrfInput.type = 'hidden';
        csrfInput.name = 'authenticity_token';
        csrfInput.value = csrf_token;
        form.appendChild(csrfInput);

        document.body.appendChild(form);
        form.submit();
    };

    const renderOmniauthButtons = () => {
        return omniauthProviders.map((provider) => (
            <Button
                key={provider.name}
                variant="outlined"
                fullWidth
                startIcon={getProviderIcon(provider.name)}
                sx={{mb: 1, textTransform: 'none', paddingY: '6px'}}
                onClick={(e) => handleSubmit(e, provider.path)}
            >
                Continue with {provider.name.charAt(0).toUpperCase() + provider.name.slice(1).replace(/_oauth2/, '')}
            </Button>
        ));
    };

    return (
        <AuthLayout title="Welcome" subtitle="Log in to your account">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    '& > *': {width: '100%', maxWidth: '400px'},
                }}
            >
                <form action="/users/sign_in" method="post" style={{width: '100%'}}>
                    <input type="hidden" name="authenticity_token" value={csrf_token}/>
                    <TextField
                        margin="dense"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="user[email]"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="dense"
                        required
                        fullWidth
                        name="user[password]"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox name="user[remember_me]" id="remember_me" color="primary"/>}
                        label="Remember me"
                        sx={{marginY: 1}}
                    />
                    <Button
                        component="a"
                        href="/users/password/new"
                        fullWidth
                        sx={{mt: 1, mb: 2, textTransform: 'none', paddingY: '6px'}}
                    >
                        Forgot password?
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mb: 1, textTransform: 'none', paddingY: '6px'}}
                    >
                        Continue
                    </Button>
                </form>
                <Divider sx={{my: 1, width: '90%'}}/>
                <Box sx={{width: '100%', textAlign: 'center', marginBottom: '16px'}}>
                    <Typography variant="subtitle2" color="textSecondary"
                                sx={{display: 'inline-block', marginX: '8px'}}>
                        OR
                    </Typography>
                </Box>
                {renderOmniauthButtons()}
                <Button
                    component="a"
                    href="/users/sign_up"
                    fullWidth
                    sx={{mb: 1, textTransform: 'none', paddingY: '6px'}}
                >
                    Sign up
                </Button>
                <Button
                    component="a"
                    href="/users/password/new"
                    fullWidth
                    sx={{mb: 1, textTransform: 'none', paddingY: '6px'}}
                >
                    Didn't receive confirmation instructions?
                </Button>
                <Button
                    component="a"
                    href="/users/unlock/new"
                    fullWidth
                    sx={{mb: 1, textTransform: 'none', paddingY: '6px'}}
                >
                    Didn't receive unlock instructions?
                </Button>
            </Box>
        </AuthLayout>
    );
};

export default Login;
