import React from 'react';
import ReactDOM from 'react-dom/client';
import Login from '../components/Login';

const rootElement = document.getElementById('login-root');
if (rootElement) {
  const propsString = rootElement.getAttribute('data-props') || '{}';
  const props = JSON.parse(propsString);
  const csrfToken = rootElement.getAttribute('data-csrf') || '';

  const root = ReactDOM.createRoot(rootElement);
  root.render(<Login {...props} csrf_token={csrfToken} />);
}
